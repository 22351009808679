import { template as template_6336744d550549b0bc711d52b1a1480f } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_6336744d550549b0bc711d52b1a1480f(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
