import { template as template_f6e5e45ac9694912b797f78c38fa33df } from "@ember/template-compiler";
const SidebarSectionMessage = template_f6e5e45ac9694912b797f78c38fa33df(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
