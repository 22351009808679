import { template as template_1096a2d985f84790b0501c06d4f9bb7f } from "@ember/template-compiler";
const FKLabel = template_1096a2d985f84790b0501c06d4f9bb7f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
